import Select from "react-select";

function Donation(props) {
  return (
    <div>
      <div className="flex justify-left">
        <div className="mb-3 w-full">
          <label htmlFor={props.name}>
            <b>{props.title} </b>
            {props.description}
          </label>
          <Select
            className="basic-single mt-2"
            classNamePrefix="select"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name={props.name}
            options={props.options}
            onChange={props.handleChange}
            value={props.selectedItem}
          ></Select>
        </div>
      </div>
      {props.selectedItem.value === -999 ? (
        <div className="flex justify-left">
          <div className="mb-3 w-80">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
              htmlFor="customDonation"
            >
              Your donation:
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="customDonation"
              type="number"
              placeholder="Custom Donation"
              onChange={props.handleCustomChange}
              value={props.selectedCustom.value}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Donation;
