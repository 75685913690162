import React from "react";
import App from "./App";
import {createRoot} from 'react-dom/client';

import { BrowserRouter as Router } from "react-router-dom";
import './index.css'; 

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
      <Router>
          <App />
      </Router>
  </React.StrictMode>
);
