import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import apiBaseClient from "./ApiBase.js";
import { useLocalStorage } from "./useLocalStorage.js";

const CartListing = (props) => {
  const [navigateTo, setNavigateTo] = useState(null);
  const [stripeCheckoutID, setStripeCheckoutID] = useLocalStorage(
    "stripeCheckoutID",
    ""
  );
  if (navigateTo) {
    window.location.href = navigateTo;
  }

  const data = {
    mode: "payment",
    line_items: props.cart.map((donation) => {
      return {
        price_data: {
          currency: "usd",
          product_data: {
            name: donation.name,
          },
          unit_amount: donation.price * 100,
        },
        adjustable_quantity: {
          enabled: false,
        },
        quantity: donation.quantity,
      };
    }),
    success_url: process.env.REACT_APP_FRONTEND_URL+`/thank-you`,
    cancel_url: process.env.REACT_APP_FRONTEND_URL,
  };

  const cartTotal = props.cart.reduce(
    (a, product) => (a = a + product.quantity * product.price),
    0
  );

  const getStripeSession = (cart) => {
    apiBaseClient
      .post("/api/get-stripe-session", {
        headers: {
          Accept: "application/json",
        },
        data,
        cart,
        user_id: props.user.id,
        organization: 'WFRN',
      })
      .then((response) => {
        if (response.data.checkout_url) {
          setStripeCheckoutID(response.data.checkout_session_id);
          setNavigateTo(response.data.checkout_url);
          console.log("checkout info");
        }
      });
  };

  return (
    <>
      <div className="flex justify-center mb-1">
        <h2 className="mb-2 font-bold">Your Donation</h2>
      </div>
      <hr className="mt-3 mb-3" />

      {props.cart.map((product, i) => {
        return (
          <div key={product.id} className="mb-3">
            <div className="flex justify-between items-center mb-1">
              <span className="font-small text-sm text-slate-800">
                {product.name}
              </span>
              <div className="font-small text-sm text-slate-800 justify-end">
                <span className="text-sm font-small text-slate-800">
                  ${product.price * product.quantity}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      <hr className="mt-2 mb-5" />
      {cartTotal > 0 && (
        <div className="flex justify-between items-center mb-1 justify-end">
          <span className="font-small text-sm text-slate-800">Total</span>
          <span className="text-sm font-small text-slate-800">
            ${cartTotal}
          </span>
        </div>
      )}
      {cartTotal === 0 && (
        <div className="flex justify-center mb-1">
          <span className="text-sm font-small text-slate-800">
            <p>You have not elected any donations</p>
          </span>
        </div>
      )}
      <div className="mt-16 items-center text-center">
        {cartTotal > 0 && (
          <button
            // variant="contained"
            onClick={(cart) => {
              if (props.navTo === "checkout") {
                //all good, send to c/o
                getStripeSession(props.cart);
                console.log(props.cart);
              }
            }}
            className="mr-3 inline-block px-6 py-2.5 bg-limey text-gray-600 font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
          >
            Complete Donation
          </button>
        )}
      </div>
    </>
  );
};

export default CartListing;
