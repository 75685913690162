// import { useEffect } from "react";
// import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import CartListing from "./CartListing.js";
//import NavButton from "./NavButton.js";
import Donation from "./Donation.js";
import { useLocalStorage } from "./useLocalStorage";
import apiBaseClient from "./ApiBase.js";
import Logo from "./Logo.js";
import { toast } from "react-toastify";

const Home = () => {
  const getPageNumber = () => {
    if (localStorage && parseInt(localStorage.getItem("currentPage")) > 0) {
      return parseInt(localStorage.getItem("currentPage"));
    }
    return 1;
  };

  const [currentPage, setCurrentPage] = useState(getPageNumber(1));

  const [myCart, setMyCart] = useLocalStorage("myCart", {});

  const [advancingInclusion, setadvancingInclusion] =
    useLocalStorage("advancingInclusionFund", {
      value: 0,
      label: "Select a donation amount",
    });
  const [earlyCareerFund, setearlyCareerFund] = useLocalStorage(
    "earlyCareerFund",
    { value: 0, label: "Select a donation amount" }
  );
  const [awardsFund, setawardsFund] = useLocalStorage("awardsFund", {
    value: 0,
    label: "Select a donation amount",
  });
  const [rmkFund, setrmkFund] = useLocalStorage("rmkFund", {
    value: 0,
    label: "Select a donation amount",
  });
  const [generalFund, setgeneralFund] = useLocalStorage(
    "generalFund",
    { value: 0, label: "Select a donation amount" }
  );

  const [AIFCustom, setAIFCustom] = useLocalStorage("AIFCustom", {
    value: -1,
    label: "",
  });
  const [ECFCustom, setECFCustom] = useLocalStorage("ECFCustom", {
    value: -1,
    label: "",
  });
  const [AFCustom, setAFCustom] = useLocalStorage("AFCustom", {
    value: -1,
    label: "",
  });
  const [rmkCustom, setrmkCustom] = useLocalStorage("rmkCustom", {
    value: -1,
    label: "",
  });
  const [GFCustom, setGFCustom] = useLocalStorage("GFCustom", {
    value: -1,
    label: "",
  });

  const [errors, setErrors] = useState("");

  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  const [user, setUser] = useLocalStorage("user", {
    id: -1,
    first_name: "",
    last_name: "",
    email: "",
    mailing_address_one: "",
    mailing_address_two: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    organization: "",
    positiontitle: "",
  });

  const handleNextClick = (event) => {
    if (currentPage === 1) {
      localStorage.setItem("currentPage", currentPage + 1);
      setCurrentPage((currentPage) => currentPage + 1);
      setErrors("");
    }

    if (currentPage === 2) {
      if (
        advancingInclusion.value > 0 ||
        earlyCareerFund.value > 0 ||
        awardsFund.value > 0 ||
        rmkFund.value > 0 ||
        generalFund.value > 0 ||
        AIFCustom.value > 0 ||
        ECFCustom.value > 0 ||
        rmkCustom.value > 0 ||
        AFCustom.value > 0 ||
        GFCustom.value > 0
      ) {
        localStorage.setItem("currentPage", currentPage + 1);
        setCurrentPage((currentPage) => currentPage + 1);
        setErrors("");
      } else {
        setErrors("Please select a donation option");
      }
    }

    if (currentPage === 3) {
      if (
        (user.first_name !== "") &
        (user.last_name !== "") &
        (user.email !== "")
      ) {
        //post the user details and get a user_id
        createUser().then((result) => {
          //console.log(result);
          // handle the result here
          localStorage.setItem("currentPage", currentPage + 1);
          setCurrentPage((currentPage) => currentPage + 1);
          setErrors("");
        }).catch((error) => {
          console.error(error);
          // handle the error here
        });
        
      } else {
        setErrors("Please provide your name and email at minimum.");
      }
    }
  };
  const createUser = () => {
    return new Promise((resolve, reject) => {
      apiBaseClient("/sanctum/csrf-cookie", {
        method: "GET",
      })
        .then((getCookieResponse) => {
          apiBaseClient
            .post("/api/register-wfrn", {
              headers: {
                Accept: "application/vnd.api+json",
                "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
              },
              email: user.email,
              first_name: user.first_name,
              last_name: user.last_name,
              institution: user.organization,
              mailing_address_one: user.mailing_address_one,
              mailing_address_two: user.mailing_address_two,
              city: user.city,
              state_province: user.state,
              country: user.country,
              postal_code: user.postal_code,
              title: user.positiontitle,
              password: "87654321",
              password_confirmation: "87654321",
              organization: "WFRN",
            })
            .then((getResponse) => {
              if (getResponse.status === 200) {
                //console.log(getResponse.data.user);
                const user = getResponse.data.user;
                setUser({...user, id: user.id} )
                resolve("success");
              } else {
                reject("failure");
              }
            })
            .catch((err) => {
              const errors = err.response.data.errors;
              var errorMessages = "";
              for (const [key, value] of Object.entries(errors)) {
                errorMessages = errorMessages + value + "\n";
              }
              reject("failure");

              toast(errorMessages);
            });
        })
        .catch((getCookieError) => {
          console.log(getCookieError);
          reject("failure");
        });
    });
  };

  const handleBackClick = (event) => {
    localStorage.setItem("currentPage", currentPage - 1);
    setCurrentPage((currentPage) => currentPage - 1);
    setErrors("");
  };

  const handleAIFSelectChange = (event) => {
    setadvancingInclusion(event);
  };
  const handleECFSelectChange = (event) => {
    setearlyCareerFund(event);
  };
  const handleAFSelectChange = (event) => {
    setawardsFund(event);
  };
  const handleRMKSelectChange = (event) => {
    setrmkFund(event);
  };
  const handleGFSelectChange = (event) => {
    setgeneralFund(event);
  };

  const handleAIFCustomChange = (event) => {
    setAIFCustom({ value: event.target.value, label: "aif-custom" });
  };
  const handleECFCustomChange = (event) => {
    setECFCustom({ value: event.target.value, label: "ecf-custom" });
  };
  const handleAFCustomChange = (event) => {
    setAFCustom({ value: event.target.value, label: "af-custom" });
  };
  const handleRMKCustomChange = (event) => {
    setrmkCustom({ value: event.target.value, label: "rmk-custom" });
  };
  const handleGFCustomChange = (event) => {
    setGFCustom({ value: event.target.value, label: "gf-custom" });
  };

  useEffect(() => {
    var items = [];
    if (generalFund) {
      if (generalFund.value > 0) {
        items.push({
          id: "-1",
          name: "General Fund Donation",
          quantity: 1,
          price: generalFund.value,
          attributes: {
            name: "General Fund Donation",
            price: generalFund.value,
          },
        });
      }
    }
    if (advancingInclusion) {
      if (advancingInclusion.value > 0) {
        items.push({
          id: "-2",
          name: "Advancing Inclusion Fund Donation",
          quantity: 1,
          price: advancingInclusion.value,
          attributes: {
            name: "Advancing Inclusion Fund Donation",
            price: advancingInclusion.value,
          },
        });
      }
    }
    if (earlyCareerFund) {
      if (earlyCareerFund.value > 0) {
        items.push({
          id: "-3",
          name: "Early Career Fund Donation",
          quantity: 1,
          price: earlyCareerFund.value,
          attributes: {
            name: "Early Career Fund Donation",
            price: earlyCareerFund.value,
          },
        });
      }
    }
    if (awardsFund) {
      if (awardsFund.value > 0) {
        items.push({
          id: "-4",
          name: "Awards Fund Donation",
          quantity: 1,
          price: awardsFund.value,
          attributes: {
            name: "Awards Fund Donation",
            price: awardsFund.value,
          },
        });
      }
    }

    if (rmkFund) {
      if (rmkFund.value > 0) {
        items.push({
          id: "-4",
          name: "Rosabeth Moss Kanter Fund Donation",
          quantity: 1,
          price: rmkFund.value,
          attributes: {
            name: "Rosabeth Moss Kanter Fund Donation",
            price: rmkFund.value,
          },
        });
      }
    }

    if (AIFCustom) {
      if (AIFCustom.value > 0 && advancingInclusion.value < 0) {
        items.push({
          id: "-5",
          name: "Advancing Inclusion Fund Donation",
          quantity: 1,
          price: Number(AIFCustom.value),
          attributes: {
            name: "Advancing Inclusion Fund Donation",
            price: Number(AIFCustom.value),
          },
        });
      }
    }
    if (ECFCustom) {
      if (ECFCustom.value > 0 && earlyCareerFund.value < 0) {
        items.push({
          id: "-6",
          name: "Early Career Fund Donation",
          quantity: 1,
          price: Number(ECFCustom.value),
          attributes: {
            name: "Early Career Fund Donation",
            price: Number(ECFCustom.value),
          },
        });
      }
    }
    if (AFCustom) {
      if (AFCustom.value > 0 && awardsFund.value < 0) {
        items.push({
          id: "-7",
          name: "Awards Fund Donation",
          quantity: 1,
          price: Number(AFCustom.value),
          attributes: {
            name: "Awards Fund Donation",
            price: Number(AFCustom.value),
          },
        });
      }
    }
    if (rmkCustom) {
      if (rmkCustom.value > 0 && rmkFund.value < 0) {
        items.push({
          id: "-7",
          name: "Rosabeth Moss Kanter Fund Donation",
          quantity: 1,
          price: Number(rmkCustom.value),
          attributes: {
            name: "Rosabeth Moss Kanter Fund Donation",
            price: Number(rmkCustom.value),
          },
        });
      }
    }

    if (GFCustom) {
      if (GFCustom.value > 0 && generalFund.value < 0) {
        items.push({
          id: "-8",
          name: "General Fund Donation",
          quantity: 1,
          price: Number(GFCustom.value),
          attributes: {
            name: "General Fund Donation",
            price: Number(GFCustom.value),
          },
        });
      }
    }
    setMyCart(items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    advancingInclusion,
    earlyCareerFund,
    awardsFund,
    rmkFund,
    generalFund,
    AIFCustom,
    ECFCustom,
    rmkCustom,
    AFCustom,
    GFCustom,
  ]);

  const myOptions = [
    { value: 0, label: "Select a donation amount" },
    { value: 50, label: "$50" },
    { value: 75, label: "$75" },
    { value: 100, label: "$100" },
    { value: 250, label: "$250" },
    { value: 500, label: "$500" },
    { value: -999, label: "Other" },
  ];

  return (
    <div className="container mx-auto w-2/3 ">
      <div className="flex items-center justify-center mt-5">
        <Logo height={140} />
      </div>
      <div className="flex items-center justify-center mt-5 text-rose-700">
        {errors}
      </div>

      {currentPage === 1 && (
        <div className="mb-6 mt-10" id="pageOne">
          <p className="mb-3">
            Thank you for your interest in making a tax-deductible donation to
            the Work and Family Researchers Network, a 501-c-3 registered
            non-profit organization.
          </p>
          <p className="mb-3">
            When you submit your donation, you can designate specific
            initiatives or support for general operating needs. If you wish to
            discuss making a large donation, which opens opportunity to
            recognize you or your organization prominently, we would welcome the
            conversation. Please email Stephen Sweet, the Executive Officer, at
            info@wfrn.org.
          </p>
          <p className="mb-3">
            Upon submission of your donation, the WFRN will immediately send a
            receipt to the email address that you provide. That receipt will
            include the WFRN employer identification number (EIN) 45-1423215,
            your donation amount, date, and contact information for the WFRN.
          </p>
          <p className="mb-3">
            On behalf of the international community of work-family researchers,
            practitioners, and advocates, I express tremendous appreciation for
            your generosity.
          </p>

          <p className="mb-2">
            Stephen Sweet
            <br />
            Executive Officer
          </p>
          <div className="flex items-center justify-center mt-5">
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => handleNextClick()}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="w max-w-med mt-10" id="pageTwo">
          <Donation
            name="advancingInclusionFund"
            title="Advancing Inclusion Fund: "
            description="Contribute to support the work of scholars who are Black, Indigenous or Persons of Color"
            options={myOptions}
            handleChange={handleAIFSelectChange}
            handleCustomChange={handleAIFCustomChange}
            selectedCustom={AIFCustom}
            selectedItem={advancingInclusion}
          />
          <Donation
            name="earlyCareerFund"
            title="Early Career Fund: "
            description="Contribute to support members who are at their early career"
            options={myOptions}
            handleChange={handleECFSelectChange}
            handleCustomChange={handleECFCustomChange}
            selectedCustom={ECFCustom}
            selectedItem={earlyCareerFund}
          />
          <Donation
            name="awardsFund"
            title="WFRN Awards Fund: "
            description="Contribute to the WFRN awards fund to recognize exceptional scholarship"
            options={myOptions}
            handleChange={handleAFSelectChange}
            handleCustomChange={handleAFCustomChange}
            selectedCustom={AFCustom}
            selectedItem={awardsFund}
          />
          <Donation
            name="rmkFund"
            title="Rosabeth Moss Kanter Award"
            description="for Excellence in Work-Family Research. Contribute to support the continued recognition of the highest-quality research in the interdisciplinary work-life field each year"
            options={myOptions}
            handleChange={handleRMKSelectChange}
            handleCustomChange={handleRMKCustomChange}
            selectedCustom={rmkCustom}
            selectedItem={rmkFund}
          />
          <Donation
            name="generalFund"
            title="WFRN General Operating Fund: "
            description=" Contribute to supporting the work of WFRN in general."
            options={myOptions}
            handleChange={handleGFSelectChange}
            handleCustomChange={handleGFCustomChange}
            selectedCustom={GFCustom}
            selectedItem={generalFund}
          />

          <div className="flex items-center justify-center mt-5">
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => handleBackClick()}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Back
            </button>
            &nbsp;
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => handleNextClick()}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {currentPage === 3 && (
        <div className="w max-w-med mt-10" id="pageThree">
          <form className="w max-w-med mt-20">
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="first_name"
                >
                  First Name (required)
                </label>
                <input
                  className="appearance-none block w-full bg-amber-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="first_name"
                  name="first_name"
                  value={user.first_name}
                  onChange={(e) =>
                    setUser({ ...user, first_name: e.target.value })
                  }
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="last_name"
                >
                  Last Name (required)
                </label>
                <input
                  className="appearance-none block w-full bg-amber-100  text-gray-700 border  border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="last_name"
                  name="last_name"
                  value={user.last_name}
                  onChange={(e) =>
                    setUser({ ...user, last_name: e.target.value })
                  }
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email (required)
                </label>
                <input
                  className="appearance-none block w-full bg-amber-100 text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  name="email"
                  value={user.email}
                  onChange={(e) =>
                    setUser({ ...user, email: e.target.value })
                  }
                  type="text"
                  placeholder="Email address"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="mailing_address_one"
                >
                  Address Line 1
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mailing_address_one"
                  name="mailing_address_one"
                  value={user.mailing_address_one}
                  onChange={(e) =>
                    setUser({ ...user, mailing_address_one: e.target.value })
                  }
                  type="text"
                  placeholder="Address Line 1"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="mailing_address_one"
                >
                  Address Line 2
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mailing_address_two"
                  name="mailing_address_two"
                  value={user.mailing_address_two}
                  onChange={(e) =>
                    setUser({ ...user, mailing_address_two: e.target.value })
                  }
                  type="text"
                  placeholder="Address Line 2"
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="city"
                >
                  City
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="city"
                  name="city"
                  value={user.city}
                  onChange={(e) =>
                    setUser({ ...user, city: e.target.value })
                  }
                  type="text"
                  placeholder="City"
                />
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="state_province"
                >
                  state_province/Province
                </label>
                <div className="relative">
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="state_province"
                    name="state_province"
                    value={user.state_province}
                    onChange={(e) =>
                      setUser({ ...user, state_province: e.target.value })
                    }
                    type="text"
                    placeholder="State/Province"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="postal_code"
                >
                  postal_code
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="postal_code"
                  name="postal_code"
                  value={user.postal_code}
                  onChange={(e) =>
                    setUser({ ...user, postal_code: e.target.value })
                  }
                  type="text"
                  placeholder="00000"
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="country"
                >
                  Country (if not US)
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="country"
                  name="country"
                  value={user.country}
                  onChange={(e) =>
                    setUser({ ...user, country: e.target.value })
                  }
                  type="text"
                  placeholder="Country (if not US)"
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="organization"
                >
                  Employer/Organization/Institution
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="organization"
                  name="organization"
                  value={user.organization}
                  onChange={(e) =>
                    setUser({ ...user, organization: e.target.value })
                  }
                  type="text"
                  placeholder="Employer/Organization/Institution"
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="positiontitle"
                >
                  Position Title
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="positiontitle"
                  name="positiontitle"
                  value={user.positiontitle}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      positiontitle: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Position Title"
                />
              </div>
            </div>
          </form>
          <div className="flex items-center justify-center mt-5">
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => handleBackClick()}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Back
            </button>
            &nbsp;
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => handleNextClick()}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {currentPage === 4 && (
        <div className="mt-20" id="pageFour">
          <div className="flex  justify-center mt-5">
            <div className="w-1/2">
              <CartListing cart={myCart} setCart={setMyCart} user={user} navTo="checkout" />
            </div>
          </div>
          <div className="flex items-center justify-center mt-5">
            <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              onClick={() => setCurrentPage(3)}
              className="mr-3 inline-block px-6 py-2.5 bg-merlot text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
