import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocalStorage } from "./useLocalStorage.js";
import Logo from "./Logo.js";
import apiBaseClient from "./ApiBase.js";
const Thanks = (props) => {
  const date = new Date();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);

  const getMyCart = () => {
    if (localStorage && localStorage.getItem("myCart")) {
      return localStorage.getItem("myCart");
    }
    return 1;
  };

  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  const [myCart, setMyCart] = useLocalStorage("myCart", getMyCart());
  const newUser = {
    first_name: "",
    last_name: "",
    email: "",
    mailing_address_one: "",
    mailing_address_two: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    organization: "",
    positiontitle: "",
  };

  const getMyUser = () => {
    if (localStorage && localStorage.getItem("user")) {
      return localStorage.getItem("user");
    }
    return newUser;
  };

  const getCheckoutID = () => {
    if (localStorage && localStorage.getItem("stripeCheckoutID")) {
      return localStorage.getItem("stripeCheckoutID");
    }
    return "";
  };

  const [user, setUser] = useLocalStorage("user", getMyUser);
  const [stripeCheckoutID, setStripeCheckoutID] = useLocalStorage(
    "stripeCheckoutID",
    getCheckoutID()
  );

  const cartTotal = myCart.reduce(
    (a, product) => (a = a + product.quantity * product.price),
    0
  );

  const message = () => {
    return (
      <>
        <p>
          {user.first_name} {user.last_name}
          <br />
          {user.email} <br />
        </p>
        <br />
        <br />
        {formattedDate + ""}
        <br />
        <br />
        <p>Dear {user.first_name},</p>
        <br />
        <p>
          Thank you for your tax-deductible donation of ${cartTotal} to the Work
          and Family Researchers Network, a 501-c-3 registered non-profit
          organization.
        </p>
        <br />
        <p>The details of your donation are provided below.</p>
        <br />
        <br />
        <table align="center">
          <tbody>
            <tr>
              <td>
                <b>Item</b>
              </td>
              <td>
                <b>Value</b>
              </td>
            </tr>
            {myCart.map((product, i) => {
              return (
                <tr key={product.id}>
                  <td> {product.name}</td>
                  <td>${product.price * product.quantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        <p className="mb-3">
          On behalf of the international work-family community of work-family
          researchers, practitioners, and advocates, I express tremendous
          appreciation for your generosity.
        </p>
        <p>
          <br />
          Sincerely,
        </p>
        <p className="mt-4">
          <br />
          <br />
          <b>Stephen Sweet</b>
          <br />
          Executive Officer
          <br />
          Work and Family Researchers Network
          <br />
          (EIN) 45-1423215
          <br />
          Department of Sociology <br />
          Muller Faculty Center <br />
          Ithaca College <br />
          Ithaca, NY 14850 <br />
          <a href="info@wfrn.org">info@wfrn.org</a>
        </p>
      </>
    );
  };

  var bodyFormData = new FormData();
  bodyFormData.append("userName", message());

  useEffect(() => {
    console.log("sending receipt");
    console.log(stripeCheckoutID);
    
    apiBaseClient("/sanctum/csrf-cookie", {
      method: "GET",
    })
      .then((getCookieResponse) => {
        apiBaseClient
          .post("/api/receipt/" + stripeCheckoutID, {
            to: user.email,
            organization: "WFRN",
            data: bodyFormData,
            headers: {
              Accept: "application/vnd.api+json",
              "X-XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
          })
          .then((getResponse) => {
            if (getResponse.status === 200) {
              toast.error("email sent");
            } else {
              toast.error(`unable to send email`);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(`unable to send email`);
          });
      })
      .catch((getCookieError) => {
        console.log(getCookieError);
      });
  }, [user.email]);

  return (
    <div className="container mx-auto w-1/2 ">
      <div className="flex items-center justify-center mt-5">
        <Logo height={140} />
      </div>
      <div>{message()}</div>
    </div>
  );
};

export default Thanks;
