//https://www.robinwieruch.de/react-router-authentication/
//https://www.freecodecamp.org/news/how-to-persist-a-logged-in-user-in-react/
//https://www.geeksforgeeks.org/how-to-share-state-across-react-components-with-context/

import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home.js";
import Thanks from "./components/Thanks.js";
import NotFound from "./components/NotFound.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
//import { Select } from "@mui/material";

const App = () => {
  //https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#using-nested-routes-and-outlet

  useEffect(() => {}, []);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thank-you" element={<Thanks />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
